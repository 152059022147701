import Login from "pages/Login";
import Register from "pages/Register";
import { Suspense, lazy } from "react";
import { Navigate, Outlet, useLocation, useRoutes } from "react-router-dom";
import { PATHS } from "./paths";
import SimpleLayout from "layouts/SimpleLayout";

import ProtectedRoutes from "components/RolesManagement/ProtectedRoutes";
import DashboardLayout from "layouts/dashborad";
import LoadingScreen from "components/LoadingScreen";
import ManageUsersPage from "pages/dashboard/manageUsers";
import ManageCategoriesPage from "pages/dashboard/ManageCategories";
import Offer from "pages/Offer";
import SubmitOffer from "pages/SubmitOffer";

const Loadable =
  (Component: React.LazyExoticComponent<() => JSX.Element>) => (props: any) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { pathname } = useLocation();
    return (
      <Suspense
        fallback={
          <LoadingScreen isProgress={pathname.includes("/dashboard")} />
        }
      >
        <Component {...props} />
      </Suspense>
    );
  };

const HomePage = Loadable(lazy(() => import("pages/Home")));
const AboutPage = Loadable(lazy(() => import("pages/About")));
const UserAccount = Loadable(lazy(() => import("pages/UserAccount")));
const CreateOffer = Loadable(lazy(() => import("pages/dashboard/CreateOffer")));
const DashOffersPage = Loadable(lazy(() => import("pages/dashboard/Offers")));
const EditOffer = Loadable(lazy(() => import("pages/dashboard/EditOffer")));
const BrowseOffers = Loadable(lazy(() => import("pages/BrowseOffers")));
const ManageReviews = Loadable(
  lazy(() => import("pages/dashboard/manageReviews"))
);
const ManagePartners = Loadable(
  lazy(() => import("pages/dashboard/managePartners"))
);
const DashSubmissionsPage = Loadable(
  lazy(() => import("pages/dashboard/submitted-users"))
);

const DASH = PATHS.DASHBOARD;

export default function Router() {
  return useRoutes([
    {
      path: PATHS.AUTH.LOGIN,
      element: <Login />,
    },
    {
      path: PATHS.AUTH.REGISTER,
      element: <Register />,
    },
    {
      path: PATHS.ABOUT.ROOT,
      element: <AboutPage />,
    },
    {
      path: "",
      element: <SimpleLayout />,
      children: [
        {
          path: "/",
          element: <HomePage />,
        },
        {
          path: "",
          element: <ProtectedRoutes />,
          children: [
            {
              path: PATHS.OFFERS.ROOT,
              element: <BrowseOffers />,
            },
            {
              path: PATHS.OFFERS.ROOT + "/:OfferId",
              element: <Offer />,
            },
            {
              path: PATHS.PROFILE.ROOT,
              element: <UserAccount />,
            },
            {
              path: PATHS.OFFERS.SUBMIT + "/:OfferId",
              element: <SubmitOffer />,
            },
          ],
        },
        {
          path: "/404",
          element: <h1>404</h1>,
        },
      ],
    },
    // Dashboard routes
    {
      element: <ProtectedRoutes allowedRoles={["MANAGER", "ADMIN"]} />,
      children: [
        {
          path: DASH.ROOT,
          element: (
            <DashboardLayout>
              <Suspense>
                <Outlet />
              </Suspense>
            </DashboardLayout>
          ),
          children: [
            {
              path: DASH.OFFER,
              element: <CreateOffer />,
            },
            {
              path: DASH.OFFERS,
              element: <DashOffersPage />,
            },
            {
              path: DASH.OFFER + "/:OfferId",
              element: <EditOffer />,
            },
            {
              path: DASH.USERS,
              element: <ManageUsersPage />,
            },
            {
              path: DASH.CATEGORIES,
              element: <ManageCategoriesPage />,
            },
            {
              path: DASH.REVIEWS,
              element: <ManageReviews />,
            },
            {
              path: DASH.PARTNERS,
              element: <ManagePartners />,
            },
            {
              path: DASH.SUBMISSIONS,
              element: <DashSubmissionsPage />,
            },
          ],
        },
      ],
    },
    {
      path: "*",
      element: <Navigate to="/404" />,
    },
  ]);
}
